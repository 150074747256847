<template>
  <div class="tabbar">
    <div class="tabs">
      <template v-for="(item, index) in tabs" :key="index">
        <div
          class="item"
          v-if="typeFun(typeof item.base_url != 'undefined')"
          
          @click="toMenu(item, index)"
        >
          <div class="icon" :class="tabsvalue == item.name ? 'onicon' : ''"><img src="/img/tabicon1.png" alt="" /></div>
          <div class="text" :class="tabsvalue == item.name ? 'ontext' : ''">{{ item.title }}</div>
        </div>
        <div
          class="item"
          v-else
          @click="toRout(item, index)"
        >
          <div class="onicon" v-if="tabsvalue == item.name"><img :src="item.img" alt="" /></div>
          <div class="icon" v-else><img :src="item.img" alt="" /></div>
          <div class="text" :class="tabsvalue == item.name ? 'ontext' : ''">{{ item.text }}</div>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import mitts from "@/bus";
const router = useRouter();

const tabs = ref({});

const tabsvalue = ref(null);

function typeFun(val) {
  return val;
}

function toMenu(item) {

  tabsvalue.value = null
  tabsvalue.value = item.name

  mitts.emit("showLoading");
  // tabsvalue.value = item.name
  router.push({
    path: "/event/1",
    query: {
      url: item.url,
    },
  });
}
function toRout(item) {
  // tabsvalue.value = item.name

  tabsvalue.value = null
  tabsvalue.value = item.name

  if (item.rout) {
    mitts.emit("showLoading");
    router.push({
      name: item.rout,
    });
  } else {
    mitts.emit("showLoading");
    router.push({
      path: item.url,
      query: item.query,
    });
  }
  if (item.text == "我的" || item.text == "首页") {
    mitts.emit("toptitle", false);
  } else {
    mitts.emit("toptitle", true);
  }
}

onMounted(() => {
  mitts.on("navs", (ntabs) => {
    tabs.value = ntabs;
  });
  
 
  mitts.on("routTo", (val) => {
    let paramsObj = {};
    if (val.indexOf("?") != -1){
      let paramsStr = val.split("?")[1];
      // 将参数部分转换为对象
      if (paramsStr) {
        let paramsArr = paramsStr.split('&');
        for (let i = 0; i < paramsArr.length; i++) {
          let param = paramsArr[i].split('=');
          paramsObj[param[0]] = param[1];
        }
      }
    }

    if (val.indexOf("/") !== 0){
      val = '/'+val;
    }
    router.push({
      path: val,
      query: paramsObj
    });


    // console.log(val);
    // router.push({
    //   name: val,
    //   query: {
    //
    //   }
    // });
  });


  mitts.on("navindex", (navindex) => {
    setTimeout(()=>{
      tabsvalue.value = navindex;
    },300)
  });
});
</script>

<style lang="less" scoped>
.tabbar {
  height: 100%;
  .tabs {
    height: 100%;
    width: 100%;
    display: flex;
    background: #f4f4f4;
    align-items: center;
    .item {
      flex: 1;
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
      .icon {
        width: 50px;
        height: 50px;
        margin-bottom: 10px;
        -webkit-filter: grayscale(1); /* Webkit */
        filter: gray; /* IE6-9 */  
        filter: grayscale(1); /* W3C */
        img {
          width: 100%;
          display: block;
        }
      }
      .text {
        font-size: @fontmini-size;
        color: @font-third-color;
        line-height: @fontmini-size;
      }
      .onicon {
        width: 50px;
        height: 50px;
        margin-bottom: 10px;
        img {
          width: 100%;
          display: block;
        }
      }
      .ontext {
        color: @theme-secondary-color;
      }
    }
  }
}
</style>
