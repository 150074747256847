<template>
  <div class="enter">
    <div class="titletab" v-if="showTop">
      <div class="tabNr">
        <template v-for="(item, index) in secondMenus">
          <div
            class="item"
            v-if="auths.indexOf(item.url) > -1"
            :class="item.selected == 1 ? 'on' : ''"
            :key="index"
            @click="changePage(item)"
          >
            {{ item.title }}<span></span>
          </div>
        </template>
      </div>
    </div>
    <div class="pageview">
      <router-view></router-view>
    </div>
    <div class="foot" v-if="ShowFood">
      <tabbar />
    </div>
    <loading v-if="showloading" />
  </div>
</template>

<script setup>
import { onBeforeRouteUpdate } from "vue-router";
import { ref, onMounted } from "vue";
import mitts from "@/bus";
import tabbar from "@/components/tabbar";
import loading from "@/components/loading";
import { useRouter } from "vue-router";

const router = useRouter();

const secondMenus = ref([])
const auths = ref([]);
const showTop = ref(true)

const showloading = ref(true);
let show_timer = null;

const ShowFood = ref(true);

function changePage(item) {
  // console.log(item)
  mitts.emit("showLoading");
  router.push({
    path: "/event/event",
    query: { url: encodeURIComponent(item.url) },
  });
}

onMounted(() => {
  onBeforeRouteUpdate((to, from) => {
    if (to.fullPath !== from.fullPath) {
      showloading.value = true;
    }
    false;
  });

  mitts.on("closeLoading", () => {
    showloading.value = false;
    if (show_timer) {
      clearTimeout(show_timer);
    }
  });


  mitts.on("showLoading", () => {
    showloading.value = true;
    if (show_timer) {
      clearTimeout(show_timer);
    }
    show_timer = setTimeout(function () {
      showloading.value = false;
      show_timer = null;
    }, 2000);
  });

  mitts.on("FootStatus", (val) => {
    ShowFood.value = val;
  });

  mitts.on("secondMenus",(val) => {
    secondMenus.value = val;
  })

  mitts.on("auths",(val) => {
    auths.value = val;
  })

  mitts.on("toptitle",(val) => {
    showTop.value = val;
  })

});
</script>

<style lang="less" scoped>
.enter {
  display: flex;
  flex-flow: column;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.pageview {
  height: 10px;
  flex-grow: 1;
}

.foot {
  height: 116px;
}

.titletab {
  display: flex;
  padding: 24px 24px 0 24px;
  margin-bottom: 60px;
  justify-content: space-between;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 30;
  .tabNr {
    display: flex;
  }
  .item {
    margin-right: 20px;
    font-size: @font-size;
    color: @font-white-color;
    position: relative;
    padding-bottom: 14px;
    span {
      position: absolute;
      background: @font-white-color;
      display: none;
      bottom: 0;
      left: 10%;
      right: 10%;
      height: 3px;
      border-radius: 1.5px;
    }
  }
  .on {
    font-weight: bolder;
    span {
      display: block;
    }
  }
  .addbtn {
    margin-left: 10px;
    font-size: 32px;
    color: @font-white-color;
    background: none;
    font-weight: 600;
  }
}

.bColor{
  .item {
    font-size: @font-size;
    color: @font-color;
    position: relative;
    padding-bottom: 14px;
    margin-right: 20px;
    span {
      position: absolute;
      background: @theme-color;
      display: none;
      bottom: 0;
      left: 10%;
      right: 10%;
      height: 3px;
      border-radius: 1.5px;
    }
  }
  .on {
    font-weight: bolder;
    span {
      display: block;
    }
  }
}
</style>
